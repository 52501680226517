<template>
  <div class="background_top">
    <!-- Desktop top | Slide Up -->
    <transition appear name="fade-slide-up" mode="out-in">
      <img
        class="background_top_desktop"
        v-if="desktop && background.desktopTop"
        :src="background.desktopTop"
        :key="background.desktopTop"
      />
    </transition>
    <!-- Mobile top | Slide Up -->
    <transition appear name="fade" mode="out-in">
      <img
        class="background_top_mobile"
        v-if="mobile && background.mobileTop"
        :src="background.mobileTop"
        :key="background.mobileTop"
      />
    </transition>
  </div>
</template>

<style lang="scss">
@import '../scss/lib';

.background_top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 100%;

  @media #{$minTablet} {
    height: auto;
    background: darkblue;
  }

  img {
    max-width: 100vw;
    position: absolute;

    top: 1px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;

    @media #{$minTablet} {
      width: 100vw;
      height: auto;
    }

    // width: 100%;
    // height: auto;
  }
}
</style>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TopImage',
  computed: {
    ...mapGetters(['mobile', 'desktop', 'background']),
  },
};
</script>
