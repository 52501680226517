<template>
  <div class="terms-holder">
    <transition name="fade">
      <div v-if="terms" class="static-page-container rtf">
        <div v-html="terms"></div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss"></style>

<script>
import axios from 'axios';

export default {
  name: 'Terms',
  data() {
    return {
      terms: null,
    };
  },
  async mounted() {
    try {
      const { data } = await axios.get('data/terms.html');
      this.terms = data;
    } catch (error) {
      console.log('error', error);
    }
  },
};
</script>
