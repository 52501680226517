<template>
  <div class="quiz-intro">
    <div class="quiz-intro_content">
      <div class="quiz-intro_top">
        <h1>
          “Може ли да ровя в пръстта?”, “Може ли да се катеря?”, “Може ли да
          направя земетресение?”.
        </h1>
        <p>
          Всички родители са чували подобни въпроси, но не всеки се вслушва
          истински в безграничното любопитство на децата си. А точно то е това,
          което им помага да разберат повече за заобикалящия ги свят и да се
          запознаят със забавната страна на науката. То е начинът, по който
          откриват кое е най-вълнуващо за тях и научават повече за интересни
          теми като археологията, физиката и астрономията.
        </p>
        <p>
          Сподели с нас как твоето дете опознава света, като отговориш на
          въпросите, и научи защо е толкова важно да насърчаваме детското
          любопитство.
        </p>
        <app-button @click.native="startQuiz" :size="mobile ? 200 : 250"
          >Започни въпросника</app-button
        >
      </div>

      <div class="quiz-intro_bottom">
        <span class="quiz-intro_moon">
          <img src="images/ornament-moon.png" alt="" />
        </span>
        <span class="quiz-intro_star"></span>
        <p>
          Попълни въпросника и
          <span>участвай в томбола за вълнуващо преживяване</span> — Нощ в
          Музейко само за теб и 50 твои приятели, както малки, така и големи!
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../../scss/lib';

.quiz-intro {
  padding-left: rem(20px);
  padding-right: rem(20px);

  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(30px);
  max-width: rem(670px);

  font-size: rem(14px);
  line-height: lh(22, 14);

  h1 {
    font-weight: $font-weight-bold;
    padding-left: rem(25px);
    padding-right: rem(25px);
    margin-bottom: rem(20px);
    @media #{$minTablet} {
      font-size: rem(18px);
      padding-left: rem(55px);
      padding-right: rem(55px);
    }
  }

  @media #{$minTablet} {
    margin-top: rem(60px);
    font-size: rem(16px);
    line-height: lh(27, 16);
  }
}

.quiz-intro_content {
  text-align: center;
  background-color: $color-white;
  border-radius: rem(10px);
  border: 2px solid $color-white;
  .button {
    margin-top: rem(20px);
    @media #{$minTablet} {
      margin-top: rem(30px);
    }
  }
}

.quiz-intro_top {
  padding-top: rem(25px);
  padding-left: rem(20px);
  padding-right: rem(20px);
  padding-bottom: rem(20px);
  p + p {
    margin-top: 0.5em;
  }
  @media #{$minTablet} {
    padding-left: rem(30px);
    padding-right: rem(30px);
    padding-bottom: rem(30px);
  }
}

.quiz-intro_bottom {
  position: relative;
  background-color: #003b65;
  color: $color-white;
  border-bottom-left-radius: rem(8px);
  border-bottom-right-radius: rem(8px);
  padding-top: rem(20px);
  padding-bottom: rem(20px);
  padding-left: rem(25px);
  padding-right: rem(25px);
  font-weight: bold;
  span {
    color: $color-yellow;
  }
  p {
    position: relative;
    z-index: 1;
  }
  @media #{$minTablet} {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    padding-left: rem(80px);
    padding-right: rem(80px);
  }
}

.quiz-intro_moon {
  display: none;
  position: absolute;
  top: rem(30px);
  left: rem(22px);
  img {
    width: rem(32px);
  }
  @media #{$minTablet} {
    display: block;
  }
}
</style>

<script>
import { SET_QUIZ_STARTED, SET_BACKGROUND } from '../../store';
import { mapGetters } from 'vuex';
export default {
  name: 'QuizIntro',
  data() {
    return {};
  },
  methods: {
    startQuiz() {
      this.$store.commit(SET_QUIZ_STARTED, true);
    },
  },
  computed: {
    ...mapGetters(['mobile']),
  },
  mounted() {
    this.$store.commit(SET_BACKGROUND);
  },
};
</script>
