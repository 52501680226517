<template>
  <div class="question">
    <transition :name="transition" mode="out-in">
      <!-- Answer info -->
      <div v-if="showInfo" key="info" class="question_info">
        <div class="question_info_holder">
          <div class="question_info_content">
            <h3>{{ question.answers[selectedAnswer].title }}</h3>
            <div v-html="question.answers[selectedAnswer].text"></div>
            <app-button @click.native="nextQuestion" :size="mobile ? 180 : 200"
              >Продължи напред</app-button
            >
          </div>
          <div class="question_info_image">
            <img :src="`/${question.answers[selectedAnswer].image}`" />
          </div>
        </div>
      </div>
      <!-- Answer -->
      <div v-else key="answers" class="question_answers">
        <h2
          class="question_title"
          :class="{ ['question_title_padded']: desktop && currentIndex !== 0 }"
        >
          {{ question.title }}
        </h2>
        <p class="question_control_info" v-if="desktop && currentIndex === 0">
          Премести ромба в позицията на правилния за теб отговор.
        </p>

        <div v-if="desktop" key="desktop">
          <div ref="track" class="drag-track">
            <img class="drag-track_bg" src="images/track-bg.png" />
            <div ref="scrub" class="drag-scrub">
              <svg
                ref="scrubArrows"
                class="drag-scrub_arrows"
                xmlns="http://www.w3.org/2000/svg"
                width="99"
                height="15"
                viewBox="0 0 99 15"
              >
                <g fill="#231F20" fill-rule="evenodd">
                  <path
                    d="M7 0l1.408 1.408L3.816 6 17 6v2L3.414 7.999l5.071 5.072-1.414 1.414L0 7.415l.207-.209L0 7l7-7zM91.408 0L90 1.408 94.592 6 81 6v2l14.072-.001L90 13.071l1.414 1.414 7.071-7.07-.246-.246.17-.169-7-7z"
                  />
                </g>
              </svg>
              <svg
                class="drag-scrub_shape"
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 44 44"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke-width="2"
                  transform="rotate(45 20.506 22.92)"
                >
                  <rect
                    width="28"
                    height="28"
                    x="7.213"
                    y="7.213"
                    fill="#FFF"
                    stroke="#42362E"
                    stroke-linejoin="square"
                    rx="4"
                  />
                  <rect
                    width="32"
                    height="32"
                    x="5.213"
                    y="5.213"
                    stroke="#FFF"
                    rx="4"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div class="answers_desktop">
            <div
              v-for="(answer, index) in question.answers"
              :class="[
                `answer_desktop`,
                `answer_desktop-${index}`,
                { active: index === selectedAnswer },
              ]"
              @click="selectedAnswer = index"
            >
              <span v-html="answer.label"></span>
            </div>
          </div>
        </div>

        <div v-else key="mobile">
          <div
            v-for="(answer, index) in question.answers"
            :class="[`answer_mobile`, `answer_mobile-${index + 1}`]"
            @click="selectedAnswer = index"
          >
            <span
              class="answer_mobile_checkbox"
              :class="{ ['active']: index === selectedAnswer }"
            ></span>
            <span v-html="answer.label"></span>
          </div>
        </div>

        <app-button
          class="btn-info-reveal"
          @click.native="onShowInfoClick"
          :disabled="selectedAnswer === null"
        >
          Избери
        </app-button>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import '../../scss/lib';

.question {
  position: relative;
  text-align: center;
  padding-bottom: rem(30px);
  text-align: left;
  @media #{$minTablet} {
    text-align: center;
    margin-top: rem(30px);
  }
}

.question_answers {
  background-color: $color-white;
  width: 100%;
  padding-top: rem(25px);
  padding-bottom: rem(20px);
  padding-left: rem(30px);
  padding-right: rem(30px);
  border-radius: rem(3px);
  @media #{$minTablet} {
    padding: 0;
    background-color: transparent;
  }
}

.question_title {
  color: #231f20;
  font-weight: bold;
  margin-bottom: rem(15px);
  @media #{$minTablet} {
    font-size: rem(30px);
  }
}

.question_title_padded {
  margin-bottom: rem(40px);
}

.answer_mobile {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-right: rem(-30px);
  padding-left: rem(30px);
  padding-top: rem(15px);
  padding-bottom: rem(15px);
  padding-right: rem(15px);
  border-bottom: 1px solid #efefef;
  line-height: lh(20, 16);
  &:first-child {
    border-top: 1px solid #efefef;
  }

  &::before {
    content: '';
    position: absolute;
    width: rem(10px);
    top: -1px;
    left: 0;
    bottom: 0px;
  }

  &.answer_mobile-1 {
    &::before {
      background-color: #ff0004;
    }
    .answer_mobile_checkbox {
      border-color: #ff0004;
      &::before {
        background-color: #ff0004;
      }
    }
  }

  &.answer_mobile-2 {
    &::before {
      background-color: #52afe4;
    }
    .answer_mobile_checkbox {
      border-color: #52afe4;
      &::before {
        background-color: #52afe4;
      }
    }
  }
  &.answer_mobile-3 {
    &::before {
      background-color: #00579c;
    }
    .answer_mobile_checkbox {
      border-color: #00579c;
      &::before {
        background-color: #00579c;
      }
    }
  }

  .answer_mobile_checkbox {
    position: relative;
    display: inline-block;
    width: rem(20px);
    height: rem(20px);
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    margin-right: rem(20px);
    flex-shrink: 0;

    &.active {
      &::before {
        opacity: 1;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 50%;
      transition: opacity 0.4s $ease-out-sine;
      opacity: 0;
      background: black;
    }
  }

  br {
    display: none;
  }
}

.question_control_info {
  display: none;
  margin-bottom: rem(45px);
  opacity: 0.5;
  font-size: rem(15px);
  @media #{$minTablet} {
    display: block;
  }
}

.drag-track {
  position: relative;
  width: 660px;
  height: 34px;
  margin-bottom: rem(20px);

  .drag-track_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  .drag-scrub {
    position: absolute;
    top: rem(-5px);
    width: 50px;
    height: 50px;
  }

  .drag-scrub_arrows {
    position: absolute;
    top: rem(16px);
    left: 50%;
    transform: translateX(-50%);
  }
}

.answers_desktop {
  display: flex;
}

.answer_desktop {
  flex-basis: 33.333%;
  color: #231f20;
  font-size: rem(16px);
  line-height: lh(20, 16);
  transition: color 0.3s $ease-out-quart;

  &.active {
    color: $color-black;
  }

  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
}

button.btn-info-reveal {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: rem(40px);
  @media #{$minTablet} {
  }
}

.question_info {
  background-color: $color-white;
  margin-top: rem(-130px);
  border-radius: rem(10px);
  border: 2px solid $color-white;

  @media #{$minTablet} {
    margin-top: 0;
  }

  @media (min-width: 850px) {
    margin-left: rem(-70px);
    margin-right: rem(-70px);
  }
}

.question_info_holder {
  display: flex;
  flex-direction: column-reverse;

  @media #{$minTablet} {
    flex-direction: row;
  }
}

.question_info_content {
  text-align: center;
  padding-top: rem(25px);
  padding-bottom: rem(25px);
  padding-left: rem(25px);
  padding-right: rem(25px);
  font-size: rem(14px);
  line-height: lh(22, 14);

  h3 {
    font-weight: bold;
    font-size: rem(16px);
    margin-bottom: rem(10px);
    @media #{$minTablet} {
      font-size: rem(20px);
    }
  }

  button {
    margin-top: rem(20px);
    @media #{$minTablet} {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media #{$minTablet} {
    flex: 50% 0 0;
    text-align: left;
    font-size: rem(16px);
    padding-top: rem(40px);
    padding-bottom: rem(30px);
    padding-left: rem(40px);
    padding-right: rem(40px);
  }
}

.question_info_image {
  overflow: hidden;

  img {
    max-width: 100vw;
    width: 100%;
    border-top-left-radius: rem(8px);
    border-top-right-radius: rem(8px);
  }

  @media #{$minTablet} {
    flex: 50% 0 0;
    position: relative;
    border-top-left-radius: 0;
    border-bottom-right-radius: rem(8px);
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SET_QUESTION_DISPLAYED } from '../../store';

import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';

gsap.registerPlugin(InertiaPlugin);
gsap.registerPlugin(Draggable);

export default {
  name: 'Question',
  props: {
    question: {
      type: Object,
      required: true,
    },
    last: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedAnswer: null, // 0, 1, 2
      showInfo: false, // Show answer info
    };
    // return {
    //   selectedAnswer: 1, // 0, 1, 2
    //   showInfo: true, // Show answer info
    // };
  },
  computed: {
    ...mapGetters(['mobile', 'desktop', 'currentIndex', 'questions']),
    transition() {
      return this.mobile ? 'card-flip' : 'fade';
    },
  },

  methods: {
    ...mapActions(['nextQuestion']),
    onShowInfoClick() {
      this.showInfo = true;
      this.$store.commit(SET_QUESTION_DISPLAYED, false);
    },
  },

  mounted() {
    const scrub = this.$refs['scrub'];
    const track = this.$refs['track'];
    const scrubArrows = this.$refs['scrubArrows'];
    const _this = this;
    if (scrub) {
      gsap.set(scrub, {
        left: -25, // width * 0.5
        x: 330,
        transformOrigin: 'center center',
      });

      gsap
        .timeline()
        .to(scrubArrows, { x: '-=10' })
        .to(scrubArrows, { x: '+=20' })
        .to(scrubArrows, { x: '-=10' });

      this.$options.draggable = Draggable.create(scrub, {
        type: 'x',
        bounds: track,
        throwProps: true,
        edgeResistance: 0.9,
        maxDuration: 0.3,
        snap: [110, 330, 550],
        onDragStart() {
          gsap.to(scrubArrows, { duration: 0.5, autoAlpha: 0 });
        },
        onThrowComplete() {
          if (this.x === 110) _this.selectedAnswer = 0;
          if (this.x === 330) _this.selectedAnswer = 1;
          if (this.x === 550) _this.selectedAnswer = 2;
        },
      });
    }
    this.$store.commit(SET_QUESTION_DISPLAYED, true);
  },
  destroyed() {
    if (this.$options.draggable && this.$options.draggable.length) {
      try {
        this.$options.draggable[0].kill();
      } catch (error) {
        console.log('Error destroying draggable', error);
      }
    }
  },
};
</script>
