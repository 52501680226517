<template>
  <div id="app">
    <div v-if="loaded">
      <app-background />
      <div class="app-content">
        <app-header />
        <div
          class="app-main"
          :style="{ backgroundColor: secondaryBackgroundColor }"
        >
          <transition appear name="fade" mode="out-in">
            <router-view class="page" />
          </transition>
          <app-footer />
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Loading spinner -->
    </div>
  </div>
</template>

<style lang="scss">
@import './scss/style.scss';

.app-content {
  position: relative;
  z-index: 100;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: $color-text-dark;
}

.app-main {
  position: relative;
  z-index: 100;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: background-color 0.8s $ease-out-sine;
}

.page {
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

import cssVars from './scss/_settings.scss';
import { SET_QUESTIONS } from './store/index';

import ScreenSize from './helpers/ScreenSize';

import AppBackground from './components/AppBackground';
import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';

export default {
  name: 'App',
  components: {
    AppBackground,
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(['mobile', 'background']),
    secondaryBackgroundColor() {
      return this.mobile && this.background.secondaryColor
        ? this.background.secondaryColor
        : 'transparent';
    },
  },
  async mounted() {
    new ScreenSize(parseInt(cssVars.tablet));

    try {
      const {
        data: { questions = [] },
      } = await axios.get('data/quiz.json');
      this.$store.commit(SET_QUESTIONS, questions);
      this.loaded = true;
    } catch (error) {
      console.log('error');
    }
  },
};
</script>
