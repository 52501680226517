import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Mutation
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_CURRENT_INDEX = "SET_CURRENT_INDEX";
export const SET_QUIZ_STARTED = "SET_QUIZ_STARTED";
export const SET_QUIZ_FINISHED = "SET_QUIZ_FINISHED";
export const SET_SCREEN_SIZE = "SET_SCREEN_SIZE"; // mobile || desktop
export const SET_BACKGROUND = "SET_BACKGROUND";
export const SET_QUESTION_DISPLAYED = "SET_QUESTION_DISPLAYED";

export const defaultBackground = {
  desktopTop: "images/backgrounds/intro-top.svg",
  desktopBottom: "images/backgrounds/intro-bottom.svg",
  mobileTop: "images/backgrounds/intro-mobile.svg",
  mobileBottom: false,
  primaryColor: "#84DADE",
  secondaryColor: "#7EBB00" // Only used for questions mobile
};

export default new Vuex.Store({
  state: {
    currentIndex: 0, // Current question index
    questions: [], // All Questions
    quizStarted: false,
    quizFinished: false,
    questionDisplayed: true,
    isDesktop: false,
    background: {
      ...defaultBackground
    },
    backgroundColor: {
      primary: "",
      secondary: ""
    }
  },
  mutations: {
    [SET_QUESTIONS](state, questions) {
      state.questions = questions;
    },
    [SET_CURRENT_INDEX](state, currentIndex) {
      state.currentIndex = currentIndex;
    },
    [SET_QUIZ_STARTED](state, started) {
      state.quizStarted = started;
    },
    [SET_QUIZ_FINISHED](state, finished) {
      state.quizFinished = finished;
    },
    [SET_SCREEN_SIZE](state, isDesktop) {
      state.isDesktop = isDesktop;
    },
    [SET_BACKGROUND](state, background) {
      state.background = background ? background : defaultBackground;
    },
    [SET_QUESTION_DISPLAYED](state, displayed) {
      state.questionDisplayed = displayed;
    }
  },
  getters: {
    currentIndex: state => state.currentIndex,
    currentQuestion: (_, getters) => getters.questions[getters.currentIndex],
    questions: state => state.questions,
    quizStarted: state => state.quizStarted,
    quizFinished: state => state.quizFinished,
    quizRunning: state => state.quizStarted && !state.quizFinished,
    screenSize: state => (state.isDesktop ? "desktop" : "mobile"),
    desktop: state => state.isDesktop,
    mobile: state => !state.isDesktop,
    background: state => state.background,
    questionDisplayed: state => state.questionDisplayed
  },
  actions: {
    nextQuestion({ commit, getters }) {
      const nextIndex = getters.currentIndex + 1;
      if (nextIndex < getters.questions.length) {
        commit(SET_CURRENT_INDEX, nextIndex);
        commit(SET_BACKGROUND, getters.questions[nextIndex].background);
      } else {
        commit(SET_QUIZ_FINISHED, true);
      }
    }
  }
});
