<template>
  <div class="">
    <quiz />
  </div>
</template>

<script>
import Quiz from '../components/quiz/Quiz';

export default {
  name: 'Home',
  data() {
    return {
      checked: true,
      radio: '',
    };
  },
  components: {
    Quiz,
  },
  mounted() {
    // TODO: Reset ?
  },
  destroyed() {
    // Reset
  },
};
</script>
