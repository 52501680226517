<template>
  <div class="quiz-finished">
    <!-- Text -->
    <div class="quiz-finished_text">
      <div v-if="mobile">
        <p>
          <strong
            >Вече 5 години доказваме на малки и големи, че в Музейко
            може.</strong
          >
          Тук твоето дете може да прави разкопки, да експериментира, дори да
          управлява багери. Отговорът ни е „може“, защото така то научава повече
          за заобикалящия го свят и за това колко далеч могат да го отведат
          знанията. Ние знаем, че играта е от съществено значение за развитието
          на едно дете и затова чрез нея представяме интересните научни факти по
          забавен и достъпен начин…
        </p>
      </div>
      <div v-if="desktop">
        <p>
          <strong
            >Вече 5 години доказваме на малки и големи, че в Музейко
            може.</strong
          >
          Тук твоето дете може да прави разкопки, да експериментира, дори да
          управлява багери. Отговорът ни е „може“, защото така то научава повече
          за заобикалящия го свят и за това колко далеч могат да го отведат
          знанията. Ние знаем, че играта е от съществено значение за развитието
          на едно дете и затова чрез нея представяме интересните научни факти по
          забавен и достъпен начин. Сега ви каним да последвате любопитството си
          в Музейко — мястото, където децата откриват света и науката от първо
          лице, докосвайки се до всяка инсталация.
        </p>
      </div>
    </div>

    <div class="quiz-finished-holder-container">
      <div class="quiz-finished-holder">
        <div class="quiz-finished-header">
          <h2>Участвай в нашата томбола</h2>
          <p>
            Регистрирай се за нашия имейл бюлетин и
            <span
              >може да спечелиш покана за необикновено преживяване — Нощ в
              Музейко</span
            >
            за теб и 50 твои приятели, както малки, така и големи.
          </p>
        </div>

        <!-- Form -->
        <quiz-form />
      </div>
    </div>

    <!-- Social shares -->
    <social-shares />
  </div>
</template>

<style lang="scss">
@import '../../scss/lib';

.quiz-finished {
  color: $color-text-dark;
  margin-top: rem(30px);
  margin-bottom: rem(150px);
  @media #{$minTablet} {
    margin-bottom: rem(60px);
    margin-top: rem(60px);
  }
}

.quiz-finished_text {
  max-width: rem(900px);
  padding-left: rem(40px);
  padding-right: rem(40px);
  margin-left: auto;
  margin-right: auto;
  font-size: rem(14px);
  line-height: lh(22, 14);
  margin-bottom: rem(30px);
  text-align: center;

  @media #{$minTablet} {
    padding-left: rem(30px);
    padding-right: rem(30px);
    font-size: rem(18px);
    line-height: lh(30, 18);
    margin-bottom: rem(60px);
  }

  p + p {
    margin-top: 0.5em;
  }
}

/**
Form holder
*/

.quiz-finished-holder-container {
  padding-left: rem(20px);
  padding-right: rem(20px);
  margin-bottom: rem(30px);
  max-width: rem(630px);
  margin-left: auto;
  margin-right: auto;
}
.quiz-finished-holder {
  text-align: center;
  background-color: $color-white;
  border: 2px solid $color-white;
  border-radius: rem(10px);
}

.quiz-finished-header {
  border-top-left-radius: rem(8px);
  border-top-right-radius: rem(8px);
  background-color: #003b65;
  padding-top: rem(22px);
  padding-bottom: rem(35px);
  padding-left: rem(35px);
  padding-right: rem(35px);
  color: $color-white;
  font-size: rem(14px);

  a,
  span {
    color: $color-yellow;
  }

  h2 {
    font-weight: bold;
    margin-bottom: rem(12px);
    font-size: rem(16px);
    @media #{$minTablet} {
      font-size: rem(20px);
    }
  }

  @media #{$minTablet} {
    padding-top: rem(25px);
    padding-bottom: rem(30px);
    padding-left: rem(40px);
    padding-right: rem(40px);
    font-size: rem(16px);
  }
}
</style>

<script>
import { SET_BACKGROUND } from '../../store';
import { mapGetters } from 'vuex';
import SocialShares from './SocialShares';
import QuizForm from './QuizForm';

export default {
  name: 'QuizFinished',
  data() {
    return {};
  },
  components: {
    SocialShares,
    QuizForm,
  },
  computed: {
    ...mapGetters(['mobile', 'desktop']),
  },
  mounted() {
    this.$store.commit(SET_BACKGROUND, {
      primaryColor: '#84DADE',
      desktopTop: 'images/backgrounds/outro-top.svg',
      desktopBottom: 'images/backgrounds/outro-bottom.svg',
      mobileTop: 'images/backgrounds/outro-top-mobile.svg',
      mobileBottom: 'images/backgrounds/outro-bottom-mobile.svg',
    });
  },
};
</script>
