<template>
  <label :class="{ ['checkbox']: true, ['checkbox-large']: large }">
    <input type="checkbox" :checked="value" @change="onChange" />
    <slot></slot>
  </label>
</template>

<style lang="scss">
.checkbox {
  -webkit-appearance: none;
}
</style>

<script>
export default {
  name: 'AppCheckbox',
  props: {
    value: Boolean,
    large: Boolean,
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.checked);
    },
  },
};
</script>
