<template>
  <div class="quiz-form">
    <!-- Response -->
    <transition name="fade" mode="out-in">
      <div ref="formResponse" class="form_response" v-if="formSent">
        <div v-if="responseError" key="error" class="form_response_error">
          <p>{{ this.responseError }}</p>
          <!-- Error icon -->
          <!-- Error text -->
        </div>
        <div v-else key="success" class="form_response_success">
          <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
            <path
              fill="#52AFE4"
              fill-rule="evenodd"
              d="M25.5 0C39.583 0 51 11.417 51 25.5S39.583 51 25.5 51 0 39.583 0 25.5 11.417 0 25.5 0zm10.96 18.818a1 1 0 00-1.414 0l-12.02 12.02-7.072-7.07a1 1 0 10-1.414 1.414l7.778 7.778.087.078a1 1 0 001.327-.078L36.46 20.232a1 1 0 000-1.414z"
            />
          </svg>
          <p>
            Благодарим ти, регистрацията ти е успешна! Ако си сред печелившите, ще се свържем с теб на посочения имейл.
          </p>
          <AppButton :size="290" href="https://muzeiko.bg/bg" target="_blank">Научи повече за Музейко</AppButton>
        </div>
      </div>
    </transition>

    <!-- Form -->

    <form ref="form" class="form" :style="{ opacity: formSent ? 0 : 1 }">
      <div class="form-label-text" :class="labelEmailClass">
        <h3>Твоят имейл:</h3>
        <input type="email" name="email" v-model="email" placeholder="email@example.com" />
      </div>

      <div class="form-label-text" :class="labelAgeClass">
        <h3>На каква възраст са твоите деца?</h3>
        <p>
          Всеки може да се запише за нашия бюлетин, но за да участваш в томболата, децата ти трябва да са на възраст между 5 и 13 години. Изреди
          възрастите със запетая.
        </p>
        <input type="text" name="age" v-model="age" placeholder="Например: 6, 10" />
      </div>

      <div class="form-label-checkbox" :class="labelAgreedTermsClass">
        <input type="checkbox" name="terms" v-model="agreedTerms" />
        <div>
          Съгласен съм с
          <router-link :to="{ name: 'Terms' }">Правилата на томболата</router-link>
        </div>
      </div>

      <div class="form-label-checkbox">
        <input type="checkbox" name="newsletter" v-model="agreedNewsletter" />
        <div>Съгласен съм да получавам информационен бюлетин от Музейко</div>
      </div>

      <div class="form-label-checkbox">
        <input type="checkbox" name="marketing" v-model="agreedMarketing" />
        <div>
          Съгласен съм Музейко да използва личните ми данни за персонализирана онлайн реклама
        </div>
      </div>

      <div class="form-container-terms">
        Натискайки бутона за участие, потвърждавам, че съм се запознал с
        <a href="https://muzeiko.bg/bg/pages/legal-information-24.html" target="_blank">Общите условия на muzeiko.bg</a>, както и с Политиката за
        поверителност на <router-link :to="{ name: 'PrivacyPolicy' }">Играта</router-link> и
        <a href="https://muzeiko.bg/bg/pages/vashite-lichni-danni-79.html" target="_blank">muzeiko.bg</a>
      </div>

      <app-button @click.native.prevent="submitForm" :size="160">Абонирай се</app-button>
    </form>
  </div>
</template>

<style lang="scss">
@import "../../scss/lib";

.quiz-form {
  position: relative;
  padding-top: rem(30px);
  padding-bottom: rem(30px);
  padding-left: rem(20px);
  padding-right: rem(20px);
  font-size: rem(14px);
  @media #{$minTablet} {
    font-size: rem;
    padding-left: rem(100px);
    padding-right: rem(100px);
  }
}

.form {
  transition: opacity 0.4s $ease-out-back;
}

.form-label-text {
  margin-bottom: rem(30px);
  h3 {
    font-weight: $font-weight-bold;
    color: $color-blue;
    font-size: rem(16px);
    margin-bottom: rem(10px);
    transition: color 0.5s $ease-out-expo;
  }
  input {
    -webkit-appearance: none;
    border: none;
    display: block;
    text-align: center;
    width: 100%;
    font-family: inherit;
    font-size: rem(20px);
    color: $color-text-dark;
    border-bottom: 2px solid $color-blue;
    transition: border-bottom 0.5s $ease-out-expo;
    margin-left: rem(15px);
    margin-right: rem(15px);

    &:hover,
    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba($color-text-dark, 0.3);
    }

    @media #{$minTablet} {
      margin-left: rem(25px);
      margin-right: rem(25px);
    }
  }

  &.error {
    h3 {
      color: $color-red;
    }
    input {
      border-bottom: 2px solid $color-red;
    }
  }

  p {
    font-size: rem(12px);
    margin-bottom: rem(15px);
  }
}

.form-label-checkbox {
  display: flex;
  align-items: flex-start;
  margin-bottom: rem(20px);

  @media #{$minTablet} {
    align-items: center;
  }

  input {
    -webkit-appearance: none;
    border-radius: 0;
    width: rem(30px);
    height: rem(30px);
    flex-shrink: 0;
    margin-right: rem(15px);
    border: 2px solid $color-blue;
    cursor: pointer;
    transition: border-color 0.5s $ease-in-circ;

    @media #{$minTablet} {
      width: rem(45px);
      height: rem(45px);
      margin-right: rem(20px);
    }

    &:hover,
    &:focus {
      outline: none;
    }

    &:checked {
      background-image: url("/icons/checkbox-check-small.svg");
      background-repeat: no-repeat;
      background-position: center center;

      @media #{$minTablet} {
        background-image: url("/icons/checkbox-check.svg");
      }
    }
  }

  div {
    text-align: left;
    font-size: rem(14px);
    line-height: lh(20, 14);
    a {
      color: $color-text-blue;
    }
  }

  &.error {
    input {
      border-color: $color-red;
    }
  }
}

.form-container-terms {
  font-size: rem(12px);
  line-height: lh(20, 12);
  margin-bottom: rem(30px);

  a {
    color: $color-text-blue;
  }
}

.form_response {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: rem(275px);
  z-index: 1;

  @media #{$minTablet} {
    width: rem(375px);
  }

  svg {
    margin-bottom: rem(20px);
  }

  p {
    margin-bottom: rem(30px);
    font-size: rem(16px);
    line-height: lh(27, 16);
    color: $color-blue;
  }
}

.form_response_error {
  p {
    color: $color-red;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import gsap from "gsap";

import SocialShares from "./SocialShares";

export default {
  name: "QuizForm",
  data() {
    return {
      email: "",
      age: "",
      agreedTerms: false,
      agreedNewsletter: false,
      agreedMarketing: false,

      formTouched: false,
      formSending: false,
      formSent: false,
      responseError: false,

      formFields: {},
      formAction: ""
    };
  },
  computed: {
    ...mapGetters(["mobile", "desktop"]),
    formValid() {
      return this.age && this.agreedTerms && /\S+@\S+\.\S+/.test(this.email) && !this.formSending;
    },
    labelEmailClass() {
      return {
        error: this.formTouched && !/\S+@\S+\.\S+/.test(this.email)
      };
    },
    labelAgeClass() {
      return {
        error: this.formTouched && !this.age.length
      };
    },
    labelAgreedTermsClass() {
      return {
        error: this.formTouched && !this.agreedTerms
      };
    }
  },
  mounted() {
    const config = window.__muzeikoConfig.form;
    this.formAction = config.action; // Action
    this.formFields = config.fields; // External configs, ex. nonce etc;
  },
  watch: {
    formSent(sent) {
      if (!sent) {
        gsap.to(this.$refs["form"], { autoAlpha: 0 });
      }
    }
  },
  methods: {
    submitForm() {
      this.formTouched = true;
      window.setTimeout(() => {
        this.formTouched = false;
      }, 3000);

      if (this.formSending || this.formSent || !this.formValid) return;

      this.formSending = true;

      const vars = {
        ...this.formFields,
        email: this.email,
        age: this.age,
        terms: this.agreedTerms,
        newsletter: this.agreedNewsletter,
        marketing: this.agreedMarketing
      };

      axios
        .post(this.formAction, { ...vars })
        .then(response => {
          if (response.data.success) {
            this.responseError = false;
          }
          if (response.data.error) {
            this.responseError = response.data.message;
          }
        })
        .catch(error => {
          this.responseError = error.message;
        })
        .finally(() => {
          this.formSending = false;
          this.formSent = true;
        });
    }
  }
};
</script>
