<template>
  <div class="background_bottom">
    <!-- Desktop bottom | Slide down -->
    <transition appear name="fade-slide-down" mode="out-in">
      <img
        class="background_bottom_desktop"
        v-if="desktop && background.desktopBottom"
        :key="background.desktopBottom"
        :src="background.desktopBottom"
      />
    </transition>

    <!-- Mobile Bottom | Slide down  -->
    <transition appear name="fade-slide-down" mode="out-in">
      <img
        class="background_bottom_mobile"
        v-if="mobile && background.mobileBottom"
        :key="background.mobileBottom"
        :src="background.mobileBottom"
      />
    </transition>
  </div>
</template>

<style lang="scss">
.background_bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.background_bottom_desktop,
.background_bottom_mobile {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  max-width: 100vw;
  width: 100vw;
  height: auto;
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BottomImage',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['mobile', 'desktop', 'background']),
  },
};
</script>
