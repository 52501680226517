<template>
  <div class="privacy-policy-holder">
    <transition name="fade">
      <div v-if="privacyPolicy" class="static-page-container rtf">
        <div v-html="privacyPolicy"></div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss"></style>

<script>
import axios from 'axios';
export default {
  name: 'PrivacyPolicy',
  data() {
    return {
      privacyPolicy: null,
    };
  },
  async mounted() {
    try {
      const { data } = await axios.get('data/privacy-policy.html');
      this.privacyPolicy = data;
    } catch (error) {
      console.log('error', error);
    }
  },
};
</script>
