<template>
  <a v-if="href" :href="href" :target="target" :class="{ [`button button-${size}`]: true, ['button-disabled']: disabled }">
    <img :src="background" />
    <span class="button_content"><slot></slot></span>
  </a>
  <button v-else :disabled="disabled" :class="{ [`button button-${size}`]: true, ['button-disabled']: disabled }">
    <img :src="background" />
    <span class="button_content"><slot></slot></span>
  </button>
</template>

<style lang="scss">
@import "../../scss/lib";

.button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: $color-white;

  .button_content {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: rem(16px);
    line-height: 1;
    font-weight: bold;

    @media #{$minTablet} {
      font-size: rem(18px);
    }
  }
}
.button-disabled {
}
</style>

<script>
export default {
  name: "AppButton",
  props: {
    href: {
      type: String
    },
    target: {
      type: String
    },
    size: {
      type: Number,
      default: 120,
      validator(value) {
        return [120, 130, 160, 180, 200, 230, 250, 290].includes(value);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    background() {
      if (this.size === 120) return "icons/btn-bg-120.svg";
      if (this.size === 130) return "icons/btn-bg-130.svg";
      if (this.size === 160) return "icons/btn-bg-160.svg";
      if (this.size === 180) return "icons/btn-bg-180.svg";
      if (this.size === 200) return "icons/btn-bg-200.svg";
      if (this.size === 230) return "icons/btn-bg-230.svg";
      if (this.size === 250) return "icons/btn-bg-250.svg";
      if (this.size === 290) return "icons/btn-bg-290.svg";
    }
  }
};
</script>
