<template>
  <transition name="fade">
    <div v-show="questionDisplayed" class="questions_progress">
      <transition :name="transition" mode="out-in">
        <span :key="currentIndex">{{ currentIndex + 1 }}</span>
      </transition>
      <span class="questions_progress_sep">/</span>
      <span>{{ questions.length }}</span>
    </div>
  </transition>
</template>

<style lang="scss">
@import '../../scss/lib';

.questions_progress {
  font-size: rem(11px);
  display: inline-block;
  color: #00579b;
  line-height: 1;
  background-color: $color-white;
  border-radius: rem(13px);
  padding: #{rem(9px)} #{rem(11px)};
}

.questions_progress_sep {
  color: #52afe4;
  display: inline-block;
  margin-left: 0.3em;
  margin-right: 0.3em;
}
</style>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'QuestionsProgress',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['currentIndex', 'questions', 'mobile', 'questionDisplayed']),
    transition() {
      return this.mobile ? 'card-flip' : 'fade';
    },
  },
};
</script>
