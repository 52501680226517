<template>
  <div
    class="app-background"
    :style="{ backgroundColor: background.primaryColor }"
  ></div>
</template>

<style lang="scss">
@import '../scss/lib';
.app-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: background-color 1.6s $ease-out-expo;
  will-change: background-color;
}
</style>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AppBackground',
  computed: {
    ...mapGetters(['background']),
  },
};
</script>
