<template>
  <header class="header">
    <top-image />
    <div
      ref="header"
      class="header_container"
      :class="{ ['header_container-quiz']: quizRunning }"
    >
      <div ref="logo" class="header_logo">
        <router-link :to="{ name: 'Home' }">
          <img
            src="images/logo.png"
            srcset="images/logo@2x.png 2x"
            width="264"
            height="158"
            alt="В Музейко може"
          />
        </router-link>
      </div>
      <!-- Current question mobile -->
      <transition name="fade">
        <questions-progress
          v-if="mobile && quizRunning"
          class="questions_progress-mobile"
        />
      </transition>
    </div>
  </header>
</template>

<style lang="scss">
@import '../scss/lib';

.header {
  position: relative;
}

.header_container {
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  will-change: height;
}

.header_logo {
  position: relative;
  transform-origin: center center;
  will-change: transform;
}

.questions_progress-mobile {
  position: absolute;
  right: rem(20px);
  top: rem(37px);
}
</style>

<script>
import { mapGetters } from 'vuex';
import gsap, { Power2 } from 'gsap';
import TopImage from './TopImage';
import QuestionsProgress from './quiz/QuestionsProgress';

export default {
  name: 'AppHeader',
  data() {
    return {};
  },
  components: {
    TopImage,
    QuestionsProgress,
  },
  computed: {
    ...mapGetters(['quizRunning', 'mobile']),
    logoScale() {
      if (!this.quizRunning && this.mobile) return 162 / 264;
      if (!this.quizRunning && !this.mobile) return 1;
      if (this.quizRunning && this.mobile) return 100 / 264;
      if (this.quizRunning && !this.mobile) return 130 / 264;
    },
    logoTop() {
      if (!this.quizRunning && this.mobile) return 65;
      if (!this.quizRunning && !this.mobile) return 85;
      if (this.quizRunning && this.mobile) return 20;
      if (this.quizRunning && !this.mobile) return 50;
    },
    headerHeight() {
      if (this.mobile) return 237;
      return this.quizRunning ? 140 : 240;
    },
  },
  watch: {
    logoScale() {
      this.animate();
    },
    logoTop() {
      this.animate();
    },
    logoHeight() {
      this.animate();
    },
  },
  methods: {
    animate(duration = 0.6) {
      gsap.to(this.$refs['logo'], {
        duration,
        scale: this.logoScale,
        y: this.logoTop,
        force3D: true,
        ease: Power2.easeOut,
      });
      gsap.to(this.$refs['header'], { duration, height: this.headerHeight });
    },
  },
  mounted() {
    gsap.set(this.$refs['logo'], { transformOrigin: 'center top' });
    this.animate(0);
  },
};
</script>
