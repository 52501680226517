<template>
  <div class="social_shares">
    <h3>Сподели анкетата</h3>
    <ul class="list-share">
      <li class="share share-facebook" @click="share('facebook')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
        >
          <path
            fill="#FFF"
            fill-rule="evenodd"
            d="M24.194 2.592v19.01a2.5 2.5 0 01-.756 1.835 2.5 2.5 0 01-1.837.757h-4.59v-9.56h3.24l.486-3.671h-3.726V8.64c0-.576.108-1.009.324-1.297.288-.324.774-.486 1.458-.486h1.944v-3.24c-.756-.108-1.692-.162-2.808-.162-1.44 0-2.583.423-3.43 1.27-.845.845-1.268 2.024-1.268 3.537v2.7H9.937v3.672h3.294v9.559H2.592a2.5 2.5 0 01-1.836-.756A2.5 2.5 0 010 21.6V2.592A2.5 2.5 0 01.756.756 2.5 2.5 0 012.592 0h19.01a2.5 2.5 0 011.835.756 2.5 2.5 0 01.757 1.836z"
          />
        </svg>
      </li>
      <li class="share share-messenger" @click="share('messenger')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="#FFF"
            fill-rule="nonzero"
            d="M5.01 23.436c.109 0 .277-.035.377-.078 2.5-1.101 2.532-1.188 2.956-1.073 7.243 1.994 15.094-2.642 15.094-10.917C23.437 4.836 18.362 0 11.745 0S0 4.836 0 11.368c0 3.416 1.404 6.368 3.689 8.408.395.355.313.56.38 2.751a.948.948 0 00.941.91zm-.317-8.744l3.45-5.46c.287-.453.955-.82 1.49-.82.324 0 .798.156 1.057.35l2.744 2.054a.827.827 0 00.425.141c.13 0 .32-.063.425-.141L17.99 8.01c.493-.377 1.14.214.808.738l-3.45 5.461c-.287.453-.955.82-1.49.82-.325 0-.798-.157-1.057-.35l-2.745-2.055a.827.827 0 00-.425-.142c-.13 0-.32.064-.425.142l-3.703 2.809c-.495.375-1.142-.218-.809-.74z"
          />
        </svg>
      </li>
      <li class="share share-whatsapp" @click="share('whatsapp')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
        >
          <path
            fill="#FFF"
            fill-rule="nonzero"
            d="M0 21.17l5.562-1.46a10.458 10.458 0 005.013 1.275h.005c5.779 0 10.59-4.706 10.59-10.49 0-2.802-1.191-5.434-3.171-7.419A10.411 10.411 0 0010.579 0C4.797 0 .09 4.706.09 10.49c0 1.848.483 3.653 1.4 5.245L0 21.17zm10.58-1.952a8.704 8.704 0 01-4.442-1.215l-.316-.189-3.299.865.88-3.218-.209-.33a8.69 8.69 0 01-1.332-4.64c0-4.806 3.912-8.719 8.723-8.719 2.33 0 4.517.907 6.161 2.556 1.645 1.65 2.656 3.837 2.651 6.167 0 4.81-4.011 8.723-8.817 8.723zm3.36-3.724c.505-.075 1.55-.633 1.767-1.247.217-.615.217-1.139.151-1.248-.061-.118-.236-.184-.496-.312-.26-.132-1.55-.765-1.791-.85-.241-.09-.416-.132-.59.132-.175.265-.676.85-.832 1.03-.151.175-.307.199-.567.066-1.54-.77-2.552-1.375-3.568-3.118-.27-.463.27-.43.77-1.432.085-.175.043-.326-.023-.458-.066-.133-.591-1.423-.808-1.947-.213-.51-.43-.44-.591-.45-.151-.009-.326-.009-.5-.009a.971.971 0 00-.7.326c-.241.265-.917.898-.917 2.188s.94 2.538 1.068 2.713c.132.174 1.848 2.82 4.48 3.96 1.663.718 2.315.78 3.147.656z"
          />
        </svg>
      </li>
      <li class="share share-viber" @click="share('viber')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="25"
          viewBox="0 0 23 25"
        >
          <path
            fill="#FFF"
            fill-rule="nonzero"
            d="M8.156 22.836c.411-.445.978-1.097 1.408-1.593 3.885.326 6.866-.42 7.207-.53.784-.254 5.221-.821 5.94-6.709.746-6.077-.36-9.914-2.354-11.648-.6-.553-3.029-2.315-8.444-2.339 0 0-6.384-.383-9.493 2.471C.69 4.218.08 6.755.015 9.898c-.067 3.142-.147 9.03 5.528 10.627h.005l-.005 2.438s-.038.988.615 1.186c.784.246 1.247-.505 1.998-1.313zm-1.951.155c0-.315.018-3.812.019-4.038v-.011c-4.81-1.333-4.527-6.346-4.475-8.969.052-2.622.548-4.772 2.013-6.218 2.632-2.386 8.052-2.032 8.052-2.032 4.579.019 6.771 1.399 7.281 1.862 1.687 1.446 2.547 4.905 1.919 9.975-.61 4.914-4.206 5.226-4.867 5.439-.284.09-2.906.741-6.2.529 0 0-2.457 2.963-3.222 3.733-.25.25-.525.227-.52-.27zm11.42-12.082c-.032-4.149-2.834-6.554-6.317-6.578-.402 0-.402.605 0 .61 3.114.023 5.68 2.122 5.708 5.973 0 .406.615.401.61-.005zm-.533 4.645v-.01c.227-.434.151-.845-.18-1.115-.586-.553-1.47-1.162-2.164-1.554-.515-.289-1.04-.11-1.257.174l-.453.568c-.232.288-.662.25-.662.25-3.142-.803-3.983-3.983-3.983-3.983s-.038-.43.25-.662l.567-.454c.284-.217.463-.742.175-1.257a14.145 14.145 0 00-.718-1.124 27.934 27.934 0 00-.837-1.045c-.278-.335-.694-.41-1.129-.184h-.01c-.902.51-1.89 1.465-1.573 2.448l.004.012c.032.11.29.93 1.225 2.62.477.87.968 1.592 1.455 2.202a12.353 12.353 0 002.003 2.003c1.484 1.182 3.833 2.391 4.83 2.67l.01.014c.982.317 1.937-.675 2.447-1.573zm-1.078-5.264c.052-2.287-1.408-4.173-4.148-4.371-.402-.028-.444.581-.043.61 2.377.174 3.63 1.743 3.582 3.747-.01.401.605.42.61.014zm-1.568-.548c-.066-1.356-.799-2.112-2.117-2.183-.402-.024-.44.586-.033.61 1.001.056 1.488.562 1.54 1.601.02.406.629.378.61-.028z"
          />
        </svg>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
@import '../../scss/lib';

.social_shares {
  text-align: center;
  color: $color-text-dark;
  h3 {
    font-weight: bold;
    margin-bottom: rem(12px);
  }
}

.list-share {
  display: flex;
  align-items: center;
  justify-content: center;
}

.share {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: rem(44px);
  height: rem(44px);
  border-radius: rem(5px);
  margin-right: rem(10px);
  &:last-child {
    margin-right: 0;
  }
  transition: background-color 0.3s $ease-in-out-quad;
}

.share-facebook {
  background-color: #1e3ea1;
  &:hover {
    background-color: darken(#1e3ea1, 5%);
  }
}
.share-messenger {
  background-color: #4f9fe4;
  &:hover {
    background-color: darken(#4f9fe4, 5%);
  }
}
.share-whatsapp {
  background-color: #15d755;
  &:hover {
    background-color: darken(#15d755, 5%);
  }
}
.share-viber {
  background-color: #7461ec;
  &:hover {
    background-color: darken(#7461ec, 5%);
  }
}
</style>

<script>
export default {
  name: 'SocialShares',
  data() {
    return {};
  },
  methods: {
    share(socialMedia) {
      const providers = {
        facebook: {
          id: 'facebook',
          url: 'https://www.facebook.com/share.php?u={url}',
          popup: {
            width: 626,
            height: 436,
          },
        },
        messenger: {
          id: 'messenger',
          url: 'fb-messenger://share?link={url}&app_id={fb_app_id}',
          popup: {
            width: 626,
            height: 436,
          },
        },
        viber: {
          id: 'viber',
          url: 'viber://forward?text={title}%20{url}',
          popup: {
            width: 626,
            height: 436,
          },
        },
        whatsapp: {
          id: 'whatsapp',
          url: 'whatsapp://send?text={title}%0A{url}',
          popup: {
            width: 626,
            height: 436,
          },
        },
      };
      const provider = providers[socialMedia];

      const url = window.location.href; // TODO: Home title
      const title = document
        .querySelector('meta[property="og:title"]')
        .getAttribute('content');
      const fbAppId = document
        .querySelector('meta[property="fb:app_id"]')
        .getAttribute('content');

      let templateUrl = provider.url || '';
      templateUrl = templateUrl.replace('{url}', encodeURIComponent(url));
      templateUrl = templateUrl.replace('{title}', encodeURIComponent(title));
      templateUrl = templateUrl.replace(
        '{fb_app_id}',
        encodeURIComponent(fbAppId)
      );

      const left = (window.innerWidth - provider.popup.width) / 2,
        top = (window.innerHeight - provider.popup.height) / 2;

      window.open(
        templateUrl,
        '',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, width=${provider.popup.width}, height=${provider.popup.height}, top=${top}, left=${left}`
      );
    },
  },
};
</script>
