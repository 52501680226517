<template>
  <div class="questions-holder">
    <questions-progress v-if="!mobile" class="questions_progress-desktop" />
    <transition :name="transition" mode="out-in">
      <question
        :key="currentQuestion.id"
        :question="currentQuestion"
        :last="currentIndex + 1 === questions.length"
      />
    </transition>
  </div>
</template>

<style lang="scss">
@import '../../scss/lib';

.questions-holder {
  width: 100%;
  padding-left: rem(20px);
  padding-right: rem(20px);
  max-width: rem(700px);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @media #{$minTablet} {
    margin-top: rem(110px);
  }
}

.questions_progress-desktop {
  margin-bottom: rem(20px);
}
</style>

<script>
import { mapGetters } from 'vuex';

import { SET_BACKGROUND } from '../../store';
import Question from './Question';
import QuestionsProgress from './QuestionsProgress';

export default {
  name: 'Questions',
  components: { Question, QuestionsProgress },
  computed: {
    ...mapGetters(['currentIndex', 'currentQuestion', 'questions', 'mobile']),
    transition() {
      return this.mobile ? 'card-flip' : 'fade';
    },
  },
  mounted() {
    this.$store.commit(
      SET_BACKGROUND,
      this.questions[this.currentIndex].background
    );
  },
};
</script>
