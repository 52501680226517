<template>
  <footer class="footer">
    <bottom-image />
    <div class="footer_container">
      <p class="footer_copy">
        Copyright © Музейко 2020. Всички права са запазени.
      </p>
      <nav class="footer_nav">
        <router-link :to="{ name: 'Terms' }">Общи условия</router-link>
        <router-link :to="{ name: 'PrivacyPolicy' }"
          >Политика за поверителност</router-link
        >
        <a href="https://muzeiko.bg/bg" target="_blank">Muzeiko.bg</a>
      </nav>
    </div>
  </footer>
</template>

<style lang="scss">
@import '../scss/lib';

.footer {
  position: relative;
  color: $color-white;
  @media #{$minTablet} {
    height: rem(85px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.footer_container {
  position: relative;
  z-index: 1;
  display: flex;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: #{rem(20px)};
  align-items: center;
  flex-direction: column-reverse;
  font-size: rem(12px);
  line-height: lh(20, 12);
  background-color: rgba($color-black, 0.15);

  @media #{$minTablet} {
    flex-direction: row;
    max-width: rem(780px);
    padding-left: rem(20px);
    padding-right: rem(20px);
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    border-top-left-radius: rem(10px);
    border-top-right-radius: rem(10px);
  }
}

.footer_copy {
  @media #{$minTablet} {
    margin-right: rem(10px);
  }
}

.footer_nav {
  a {
    font-weight: $font-weight-bold;
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>

<script>
import BottomImage from './BottomImage';

export default {
  name: 'AppFooter',
  components: {
    BottomImage,
  },
  data() {
    return {};
  },
};
</script>
