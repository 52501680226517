import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Terms from '../views/Terms.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';

Vue.use(VueRouter);

const pageTitle = document.title;

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {},
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      title: 'Правила и условия',
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Политика за поверителност',
    },
  },
  {
    path: '*',
    redirect: { name: 'Home' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const title = to.meta.title;
  document.title = title ? `${pageTitle} | ${title} ` : pageTitle;
  next();
});

export default router;
