<template>
  <div class="quiz">
    <transition name="fade" mode="out-in">
      <quiz-intro v-if="!quizStarted" />
      <questions v-else-if="quizRunning" />
      <quiz-finished v-else />
    </transition>
  </div>
</template>

<style lang="scss">
@import '../../scss/lib';

.quiz {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: background-color 0.5s $ease-out-sine;
  margin-top: -1px; // little over the background
  @media #{$minTablet} {
    margin-top: 0;
  }
}
</style>

<script>
import { mapGetters } from 'vuex';
import QuizIntro from './QuizIntro';
import Questions from './Questions';
import QuizFinished from './QuizFinished';

export default {
  name: 'Quiz',
  data() {
    return {};
  },
  components: {
    QuizIntro,
    Questions,
    QuizFinished,
  },
  computed: {
    ...mapGetters(['quizStarted', 'quizFinished', 'quizRunning']),
  },
};
</script>
