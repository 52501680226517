import gsap from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import AppCheckbox from './components/controls/AppCheckbox.vue';
import AppButton from './components/controls/AppButton.vue';

Vue.config.productionTip = false;

gsap.registerPlugin(CSSPlugin);

[AppCheckbox, AppButton].forEach((Component) =>
  Vue.component(Component.name, Component)
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
