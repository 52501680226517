import store, { SET_SCREEN_SIZE } from '../store';

export default class ScreenSize {
  constructor(minDesktop = 750) {
    this.minDesktop = minDesktop; // TODO: get from SCSS
    this.width = window.innerWidth;

    this.desktop = this.width >= this.minDesktop;

    this.onChange = this.onChange.bind(this);
    this.onResize = this.onResize.bind(this);

    window.addEventListener('resize', this.onResize);
    this.onChange();
  }

  onResize() {
    const winWidth = window.innerWidth;
    const isDesktop = winWidth >= this.minDesktop;
    if (isDesktop !== this.desktop) {
      this.desktop = isDesktop;
      this.onChange();
    }
  }

  onChange() {
    store.commit(SET_SCREEN_SIZE, this.desktop);
  }
}
